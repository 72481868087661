<template>
  <div>
    <LiefengModal
        title="修改系统"
        :value="controlForm.formFlag"
        :fullscreen="true"
        :width="600"
        :height="600"
        @input="closeModal"
    >
      <template v-slot:contentarea>
        <div class="container">
          <div class="menu">
            <Menu theme="light" :active-name="controlForm.showMenu" @on-select="activeMenu">
              <MenuItem name="baseInfo">
                <Icon type="ios-information-circle" />系统配置信息
              </MenuItem>
              <MenuItem name="authInfo">
                <Icon type="ios-settings" />安全设置
              </MenuItem>
            </Menu>
          </div>
          <div class="content">
            <Form
                :model="formData"
                :label-colon="true"
                :label-width="160"
                v-show="controlForm.showMenu == 'baseInfo'"
                ref="form"
            >

              <FormItem>
              <span slot="label" class="validate">系统名称:</span>
              <Input style="width:300px" v-model.trim="formData.paraJson.systemName" :maxlength="50"
                     placeholder="请填写系统名称"></Input>
            </FormItem>
            <FormItem>
              <span slot="label" class="validate">系统编码:</span>
              <Input style="width:300px" v-model.trim="formData.paraJson.systemCode" :maxlength="10"
                     placeholder="不能大于10位"></Input>
            </FormItem>
            <FormItem>
              <span slot="label" class="validate">系统类型:</span>
              <Select v-model="formData.systemId" style="width:200px;" placeholder="系统类型">
                <Option v-for="(item,index) in controlForm.fatherSystemList" :value="item.systemId" :key="index">{{
                    item.appName
                  }}
                </Option>
              </Select>
            </FormItem>
            <FormItem>
              <span slot="label" class="validate">系统首页地址:</span>
              <Input style="width:300px" v-model.trim="formData.paraJson.systemIndex" :maxlength="50"
                     placeholder="请填写首页地址"></Input>
            </FormItem>
            
            <FormItem v-if="showTenantIdItem" label="系统租户" prop="tenantId">
              <Select v-model="formData.tenantId" style="width:200px;" placeholder="系统租户">
                <Option v-for="(item,index) in controlForm.tenantList" :value="item.tenantId" :key="index">{{
                    item.oemName
                  }}
                </Option>
              </Select>
            </FormItem>
              <FormItem style="display:inline-block;width:260px"> 
                <span slot="label">Logo上传:</span>
                <LiefengUpload ref="LiefengUploadLogo" v-if="showUploadLogo" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList='controlForm.defaultListLogo' :showView="true"></LiefengUpload>
              </FormItem>
              <span style="color: #c4c4c4;line-height:80px">说明：72 x 72px</span>
              <br>
              <FormItem style="display:inline-block;width:260px"> 
                <span slot="label">形象图片:</span>
                <LiefengUpload ref="LiefengUploadImage" v-if="showUploadImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList='controlForm.defaultListImage' :showView="true"></LiefengUpload>
              </FormItem>
              <span style="color: #c4c4c4;line-height:80px">说明：500 x 300px</span>
              <br>
              <FormItem label="顶部菜单背景颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.topBackColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="顶部菜单字体颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.topWordColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="顶部菜单选中背景颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.topChooseBackColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="顶部菜单选中字体颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.topChooseWordColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="左侧菜单背景颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.leftBackColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="左侧菜单字体颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.leftWordColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="左侧菜单选中背景颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.leftChooseBackColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="左侧菜单选中字体颜色">
                <Input style="width:300px" v-model.trim="formData.paraJson.leftChooseWordColor" :maxlength="50"
                       placeholder="如 #ffffff"></Input>
              </FormItem>
              <FormItem label="系统描述">
                <Input style="width:300px" v-model.trim="formData.remark" type="textarea" rows="4"
                       placeholder="备注"></Input>
              </FormItem>

            </Form>

            <Form
                :model="formData"
                :label-colon="true"
                :label-width="100"
                v-show="controlForm.showMenu == 'authInfo'"
            >
              <div class="securityDiv">
                <p><span>登录设置</span></p>
              </div><br>
              <FormItem label="单一登录">
                <Select v-model="formData.paraJson.singleLogin" style="width:200px;" placeholder="单一登录">
                  <Option value="0">无限制</Option>
                  <Option value="1">后登录踢出前登录</Option>
                </Select>
              </FormItem>
              <FormItem label="超时退出">
                <Input v-model.trim="formData.paraJson.overtimeSignOut" style="width:100px"><span slot="append">分钟</span> </Input>
              </FormItem>
              <div class="securityDiv">
                <p><span>访问设置</span></p>
              </div><br>
              <FormItem label="开启验证">
                <i-switch true-value="1" false-value="0" v-model="formData.paraJson.visitVerification"/>
              </FormItem>
              <FormItem label="允许访问IP">
                <Input type="textarea" rows="4" v-model.trim="formData.paraJson.allowIps" style="width:300px" placeholder="多个ip请换行填写"></Input>
              </FormItem>
            </Form>
          </div>
        </div>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
        <Button type="primary" @click="saveData">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import LiefengUpload from '@/components/LiefengUpload';

export default {
  components: {LiefengModal, LiefengUpload},

  data() {
    return {
      tenantId: '',
      controlForm: {
        tenantList: [],
        defaultListLogo:[],
        defaultListImage:[],
        id:'1',
        formFlag: false,
        showMenu:'baseInfo',
        activeMenu:'1',
        timer:'',
        fatherSystemList: '',
      },
      formData: {
        tenantSystemId:'',
        backgroundImg:'',
        logo:'',
        orgSystemId: '',
        tenantId: '',
        systemId: '',
        paraJson: {
          systemName: '',
          systemCode: '',
          systemIndex: '',
          systemLogo: '',
          systemImage: '',
          topBackColor: '',
          topWordColor: '',
          topChooseBackColor: '',
          topChooseWordColor: '',
          leftBackColor: '',
          leftWordColor: '',
          leftChooseBackColor: '',
          leftChooseWordColor: '',
          singleLogin: '0',
          overtimeSignOut: '30',
          visitVerification: '0',
          allowIps: '',
        },
        remark: '',
      },
      formDataValidate: {
        paraJson: {
          systemName: [
            {required: true, message: '系统名称不能为空', trigger: 'blur'}
          ],
          systemCode: [
            {required: true, message: '系统编码不能为空', trigger: 'blur'}
          ],
          systemIndex: [
            {required: true, message: '系统首页地址不能为空', trigger: 'blur'}
          ],
        },
        systemId: [
          {required: true, message: '系统类型不能为空', trigger: 'change'}
        ],
      },
      showUploadLogo: true,
      showUploadImage: true,
    }

  },

  methods: {
    activeMenu(menu){
      this.controlForm.showMenu = menu;
    },
    changeEndTime(time){
      this.formData.endTime = time;
    },
    saveData() {
      if(!this.formData.paraJson.systemName.trim()){
        this.$Message.warning({
          content:'系统配置信息-系统名称不能为空',
          background:true
        });
        return
      }
      if(!this.formData.paraJson.systemCode.trim()){
        this.$Message.warning({
          content:'系统配置信息-系统编码不能为空',
          background:true
        });
        return
      }
      if(!this.formData.paraJson.systemIndex.trim()){
        this.$Message.warning({
          content:'系统配置信息-系统首页地址不能为空',
          background:true
        });
        return
      }
      if(!this.formData.systemId){
        this.$Message.warning({
          content:'系统配置信息-系统类型不能为空',
          background:true
        });
        return
      }
      // this.$refs.form.validate((valid)=> {
            this.formData.paraJson.systemLogo = this.$refs.LiefengUploadLogo.uploadList[0] ?  this.$refs.LiefengUploadLogo.uploadList[0].url : '';
            this.formData.paraJson.systemImage = this.$refs.LiefengUploadImage.uploadList[0] ?  this.$refs.LiefengUploadImage.uploadList[0].url : '';

            // if (this.formData.paraJson.systemLogo) {
            //   this.defaultList = [{ name: "", url: res.data.bgImage }];
            // }

            // if (valid) {
              this.$post('/auth/api/auth/pc/tenantSystem/update',{
                systemId:this.$route.query.systemId,
                tenantId:this.formData.tenantId,
                systemName:this.formData.paraJson.systemName,
                tenantSystemId:this.formData.tenantSystemId,
                extra:{IndexParam:JSON.stringify(this.formData.paraJson)}
              }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                      content:'修改成功',
                      background:true
                    });
                    this.closeModal();
                    this.$emit('resetBtn')
                }else{
                  this.$Message.error({
                    content:res.desc,
                    background:true
                  })
                  return
                }
              })
            // }
      //     }
      // );
    },
    closeModal(flag){
      
        //关闭弹窗
      this.controlForm.formFlag = flag;
      //清空表单
      Object.assign(this.$data.formData, this.$options.data().formData);

      //重置表单字段Form
      this.$refs['form'].resetFields();

      this.showUploadLogo = false;
      this.showUploadImage = false;
      this.$nextTick(()=>{this.showUploadLogo = true; this.showUploadImage = true;})
      if (this.tenantId) {
        this.formData.tenantId = this.tenantId
        this.$("#tenantIdItem").hide();
      }
      this.controlForm.showMenu = "baseInfo"
      //父页面刷新列表
      // if(!flag){
      //   this.$emit('resetBtn')
      // }
    },
    getSonSystemDetail(tenantSystemId) {
      this.$get('/auth/api/auth/pc/tenantSystem/getSonSystemDetail',{
        tenantSystemId
      }).then(res =>{
        if(res.code == 200){
            this.formData = res.data
            this.formData.paraJson = JSON.parse(this.formData.extra.IndexParam) 
            this.showUploadLogo = false
            this.showUploadImage = false
            this.$nextTick(()=>{
              this.showUploadLogo = true
              this.showUploadImage = true
              if (this.formData.paraJson.systemLogo) {
                this.controlForm.defaultListLogo = [{ name: "", url: this.formData.paraJson.systemLogo }];
              } else {
                this.controlForm.defaultListLogo = []
              }
              if (this.formData.paraJson.systemImage) {
                this.controlForm.defaultListImage = [{ name: "", url: this.formData.paraJson.systemImage }];
              } else {
                this.controlForm.defaultListImage = []
              }
            })

        }else{
          this.$Message.error({
            content:'获取系统详情失败',
            background:true
          })
          return
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>

.securityDiv {
  border-width:0px;
  margin-left: 20px;
  width:72px;
  height:24px;
  display:flex;
  font-family:'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight:650;
  font-style:normal;
  color:#10BC92;
}

.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 123px);
  /deep/.menu {
    height: 100%;
    .ivu-menu {
      height: 100%;
      width: 220px !important;
    }
  }
  .content {
    overflow: auto;
    padding-top: 20px;
    flex: 1;
    height: 100%;
  }
}

/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ #modal_contentarea{
  overflow: inherit !important;
}
</style>